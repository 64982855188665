import React from "react";
class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id || "",
      type: props.type || "",
      colclass: props.colclass || ""
    };
  }

  changeHandel = event => {
    if (typeof this.props.onClick === "function") {
      this.props.onClick(event);
    }
  };

  render() {
    const { id, type } = this.state;
    var { children, disabled } = this.props;
    return (
      <button
        type={type}
        id={id}
        disabled={disabled}
        className={this.props.className}
        onClick={this.changeHandel}
      >
        {this.props.title}
        {children}
      </button>
    );
  }
}

export default Button;
