import React, { Component } from 'react';
import Select, { components } from 'react-select';
import Button from "../elements/Button";
import { GeneralService } from '../../services';
import Loader from '../elements/Loader';
import MRPCarQuotes from './MRPCarQuotes';
import Modal from '../elements/Modal';
import { VEHICLE_TYPE, MAX_YEAR } from '../../config/constant';
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import "react-datepicker/dist/react-datepicker.css";
import md5 from 'md5';
import { MultiSelectValueContainer, InputOption } from '../elements/MultiSelectCustomComponents'; 

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};


class FinancierMRP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      API_PASSWORD: "",
      password: "",
      customer_region_options: [],
      customer_area_options: [],
      make_year_options: [],
      market_mrp_city_options: [],
      financier_make_options: [],
      financier_model_options: [],
      financier_version_options: [],
      market_make_options: [],
      market_model_options: [],
      market_version_options: [],
      customer_region: 0,
      customer_area: 0,
      oto_make_year: 0,
      market_mrp_make_year: 0,
      market_mrp_city: [],
      financier_make: 0,
      financier_model: 0,
      financier_version: 0,
      market_make: 0,
      market_model: 0,
      market_version: 0,
      // have_customer_region: false,
      have_market_mrp_city: false,
      errors: {},
      loading: false,
      financier_mrp_data: null,
      market_mrp_data: null,
      basicAuth: false,
      error: "",
      dealer_options: [],
      lastActivity: Date.now(),
      dealer: 0,
      vehicle_type: '',
      tax_validity: null,
      tax_amount: 0,
      required_form_fields: {
        customer_region: "required",
        oto_make_year: "required",
        financier_make: "required",
        financier_model: "required",
        financier_version: "required",
        dealer: "required",
        vehicle_type: "required",
        tax_validity: "required",
      },
      loan_amount_offer: ""
    };
  }

  checkAuth = (e) => {
    let password = this.state.password || '';
    password = (password && md5(password)) || '';
    if (password && password === this.state.API_PASSWORD) {
      localStorage.setItem("basicAuth", true);
      this.startInactivityTimer();
      this.setupEventListeners();
      this.setState({ basicAuth: true });
      this.getInventoryFundingConfigData();
    } else {
      localStorage.clear();
      this.setState({ error: "Wrong Password! Please enter a correct Password." });
    }
  }

  componentWillUnmount() {
    this.clearInactivityTimer();
    this.removeEventListeners();
  }

  startInactivityTimer = () => {
    this.inactivityInterval = setInterval(this.logoutAfterOneHour, 5000); // Check every 5 seconds
  };

  clearInactivityTimer = () => {
    clearInterval(this.inactivityInterval);
  };

  setupEventListeners = () => {
    window.addEventListener('mousemove', this.handleUserActivity);
    window.addEventListener('keydown', this.handleUserActivity);
    window.addEventListener('click', this.handleUserActivity);
    window.addEventListener('scroll', this.handleUserActivity);
  };

  removeEventListeners = () => {
    window.removeEventListener('mousemove', this.handleUserActivity);
    window.removeEventListener('keydown', this.handleUserActivity);
    window.removeEventListener('click', this.handleUserActivity);
    window.removeEventListener('scroll', this.handleUserActivity);
  };

  logoutAfterOneHour = () => {
    const { lastActivity } = this.state;
    const now = Date.now();
    const timeSinceLastActivity = now - lastActivity;
    const oneHourInMilliseconds = 60 * 60 * 1000;

    // console.log(timeSinceLastActivity, oneHourInMilliseconds)
    if (timeSinceLastActivity >= oneHourInMilliseconds) {
      this.setState({ basicAuth: false });
      localStorage.clear();
      this.clearInactivityTimer();
      this.removeEventListeners();
    }
  };

  handleUserActivity = () => {
    this.setState({ lastActivity: Date.now() });
  };

  componentDidMount = async () => {
    // document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.setState({ basicAuth: localStorage.getItem("basicAuth") });
    this.setState({ loading: true })
    if (!localStorage.getItem("basicAuth")) {
      await this.getAuth();
    } else {
      this.startInactivityTimer();
      this.setupEventListeners();
      this.getInventoryFundingConfigData();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    let { have_market_mrp_city, market_make_options, oto_make_year, customer_region } = this.state;
    if (prevState.customer_region !== customer_region) this.getMMVbyYear();
    if (prevState.oto_make_year !== oto_make_year) this.getMMVbyYear();

    if (prevState.have_market_mrp_city !== have_market_mrp_city) {

      if (have_market_mrp_city && !market_make_options.length) {
        this.getInventoryFundingConfigData("market_mmv");
      }
    }
  }

  getAuth = async () => {
    await GeneralService.getAuth()
      .then(resp => {
        if (resp.status === 200 && resp.data.status === 200) {
          let { data } = resp.data;
          if (data) {
            this.setState({ API_PASSWORD: data.password });
          }
        }
      });
  }

  getInventoryFundingConfigData = (config_type = "financier_data") => {
    this.setState({ loading: true });
    let body = {
      "source": "WEB",
      "sub_source": "car_mrp",
      "type": config_type
    };
    GeneralService.getInventoryFundingConfigData(body)
      .then(resp => {
        if (resp.status === 200 && resp.data.status === 200) {
          let { data } = resp.data, stateObj = {};
          if (config_type === "financier_data") {
            stateObj = {
              customer_region_options: data.customer_region || [],
              make_year_options: data.make_year || [],
              market_mrp_city_options: data.market_mrp_city || [],
              dealer_options: (data && data.dealer_list && data.dealer_list.map(dealer => {
                return {
                  id: dealer.id,
                  value: dealer.organization + " (" + dealer.gcd_code + ")",
                  is_priority_dealer: dealer.is_priority_dealer
                }
              })) || []
            };
          } else if (config_type === "financier_mmv") {
            stateObj = {
              financier_make_options: data.financier_make || [],
              financier_model_options: data.financier_model || [],
              financier_version_options: data.financier_version || []
            };
          } else if (config_type === "market_mmv") {
            stateObj = {
              market_make_options: data.market_make || [],
              market_model_options: data.market_model || [],
              market_version_options: data.market_version || []
            };
          }
          stateObj = {
            ...stateObj,
            // have_customer_region: false,
            have_market_mrp_city: false,
          }
          this.setState(stateObj);
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.setState({ loading: false });
      })
  }

  getMMVbyYear = () => {
    this.setState({ loading: true });
    let body = {
      "financier_id": 13,
      "area_id": this.state.customer_region,
      "make_year": this.state.oto_make_year
    };
    if(body.area_id && body.make_year){
      GeneralService.getMMVbyYear(body)
        .then(resp => {
          if (resp.status === 200) {
            let { data } = resp.data, stateObj = {};
            stateObj = {
              financier_make_options: data?.make || [],
              financier_model_options: data?.model || [],
              financier_version_options: data?.version || []
            };
            stateObj = {
              ...stateObj,
              financier_make: 0,
              financier_model: 0,
              financier_version: 0,
              // have_customer_region: false,
              have_market_mrp_city: false,
            }
            this.setState(stateObj);
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.setState({ loading: false });
        })
    }else{
      this.setState({ loading: false });
    }
  }


  handleChangeOption = (field_name, field_obj, isMulti = false) => {
    let { market_mrp_city, errors, required_form_fields } = this.state;
    let stateObj = {};
    if (isMulti === true && field_obj && Array.isArray(field_obj)) {
      let city_ids = field_obj.map(v => v.city_id);
      stateObj = { [field_name]: city_ids }
      // For adding or removing rules from Market MRP form
      if (city_ids.length) {
        required_form_fields = {
          ...required_form_fields,
          market_mrp_make_year: "required",
          market_make: "required",
          market_model: "required",
          market_version: "required"
        }
      } else {
        delete required_form_fields.market_mrp_make_year;
        delete required_form_fields.market_make;
        delete required_form_fields.market_model;
        delete required_form_fields.market_version;
      }
      if (field_name === "market_mrp_city" && market_mrp_city.length === 0) stateObj["have_market_mrp_city"] = true;
      this.setState({ required_form_fields })
    } else if (field_obj && field_obj.hasOwnProperty('id')) {
      stateObj = { [field_name]: field_obj.id };
      // if (field_name === "customer_region" && customer_region === 0) stateObj["have_customer_region"] = true;
      if (field_name === "financier_make") {
        stateObj["financier_model"] = 0;
        stateObj["financier_version"] = 0;
      }
      if (field_name === "financier_model") {
        stateObj["financier_version"] = 0;
      }
      if (field_name === "market_make") {
        stateObj["market_model"] = 0;
        stateObj["market_version"] = 0;
      }
      if (field_name === "market_model") {
        stateObj["market_version"] = 0;
      }
    } else if (field_obj && field_obj.hasOwnProperty('v_id')) {
      stateObj = { [field_name]: field_obj.v_id };
    } else {
      stateObj = { [field_name]: field_obj };
    }
    delete errors[field_name];
    stateObj = {
      ...stateObj,
      errors: errors
    }
    this.setState(stateObj);
  }

  isValidForm = () => {
    let isValid = true;
    let errorsObj = {}

    Object.keys(this.state.required_form_fields).forEach(element => {
      if (!this.state[element]) {
        isValid = false;
        errorsObj = { ...errorsObj, [element]: "The " + element.split('_').join(' ') + " field is required" }
      }
    });
    this.setState({ errors: errorsObj })
    return isValid
  }

  getInventoryCarQuotes = (e) => {
    e.preventDefault();
    let isValid = this.isValidForm();
    if (isValid) {
      let { customer_region, oto_make_year, financier_make, financier_model, financier_version, market_mrp_city, market_mrp_make_year, market_make, market_model, market_version, customer_region_options, market_mrp_city_options, dealer, vehicle_type, dealer_options, financier_make_options, financier_model_options, financier_version_options, tax_amount, tax_validity } = this.state;

      let is_pd = dealer_options.filter(({ id }) => id === dealer).length ? dealer_options.filter(({ id }) => id === dealer)[0]["is_priority_dealer"] : "0";
      let customerRegionName = customer_region ? customer_region_options && customer_region_options.find(v => v.id === customer_region) : "";
      customerRegionName = (customerRegionName && customerRegionName.name) || "";
      // let customerCityName = customer_area ? customer_area_options && customer_area_options.find(v => v.id === customer_area) : "";
      // customerCityName = (customerCityName && customerCityName.name) || "";
      let make_name = financier_make_options.find(v => v.id === financier_make)?.make;
      let model_name = financier_model_options.find(v => v.id === financier_model)?.model;
      let version_name = financier_version_options.find(v => v.id === financier_version)?.version;

      let body = {
        "financier_mrp": {
          "region": customerRegionName,
          // "city": customerCityName,
          "area_id": customer_region,
          "make": make_name,
          "make_id": financier_make,
          "model": model_name,
          "model_id": financier_model,
          "variant": version_name,
          "variant_id": financier_version,
          "make_year": oto_make_year,
          "dealer_id": dealer,
          "is_pd": is_pd === "1" ? 1 : 0,
          "tax_amount": Number(tax_amount),
          "tax_validity": dateFormat(new Date(tax_validity), "mm/yyyy"),
          "car_type": vehicle_type
        }
      }
      if (market_mrp_city && market_mrp_city.length) {
        let mrpCityData = market_mrp_city.map(id => {
          let cityData = market_mrp_city_options.find(v => v.city_id === id);
          return {
            id: cityData.city_id || "",
            name: cityData.city_name || ""
          }
        });
        body["market_mrp"] = {
          "city_id": mrpCityData,
          "make_id": market_make,
          "model_id": market_model,
          "version_slug": market_version,
          "make_year": market_mrp_make_year
        }
      }
      this.setState({ loading: true })
      GeneralService.getInventoryFundingCarQuote(body)
        .then(resp => {
          if (resp.status === 200 && resp.data.status === 200) {
            let { data } = resp.data, stateObj = {};
            if (data.hasOwnProperty("financier_mrp_data")) {
              stateObj["financier_mrp_data"] = (data.financier_mrp_data) || null;
            }
            if (data.hasOwnProperty("market_mrp_list")) {
              stateObj["market_mrp_data"] = (data.market_mrp_list && data.market_mrp_list.data) || null;
            }
            if (data.hasOwnProperty("loan_amount_offer")) {
              stateObj["loan_amount_offer"] = data.loan_amount_offer || 0;
            }
            if (data.hasOwnProperty("car_otr")) {
              stateObj["car_otr"] = (data.car_otr) || 0;
            }
            this.setState(stateObj);
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.setState({ loading: false });
        })
    }
  }

  backToFormHandler = () => {
    this.setState({ financier_mrp_data: null });
  }

  render() {
    const { customer_region_options, make_year_options, market_mrp_city_options, customer_region, oto_make_year, market_mrp_city, market_mrp_make_year, financier_make_options, financier_model_options, financier_version_options, market_make_options, market_model_options, market_version_options, financier_make, financier_model, financier_version, market_make, market_model, market_version, errors, loading, financier_mrp_data, market_mrp_data, dealer_options, dealer, vehicle_type, tax_amount, tax_validity, loan_amount_offer, car_otr } = this.state;
    // let CustomerAreaOptions = customer_region ? (customer_area_options && customer_area_options.length ? customer_area_options.filter(v => v.cluster_id === customer_region) : []) : []

    let OtoModelOptions = financier_make ? (financier_model_options && financier_model_options.length ? financier_model_options.filter(v => v.make_id === financier_make) : []) : [];
    let OtoVersionOptions = financier_make && financier_model ? (financier_version_options && financier_version_options.length ? financier_version_options.filter(v => v.make_id === financier_make && v.model_id === financier_model) : []) : [];

    let marketMakeName = market_make ? market_make_options.find(v => v.id === market_make) : null;
    marketMakeName = (marketMakeName && marketMakeName.make) || "";

    let MarketModelOptions = marketMakeName ? (market_model_options && market_model_options.length ? market_model_options.filter(v => v.mk_id === marketMakeName) : []) : [];

    let MarketVersionOptions = marketMakeName && market_model ? (market_version_options && market_version_options.length ? market_version_options.filter(v => v.mk_id === marketMakeName && v.md_id === market_model) : []) : [];

    let selectedDealerName = dealer && dealer_options ? dealer_options.find(v => v.id === dealer)?.value : '';

    if (financier_mrp_data) {
      return (
        <MRPCarQuotes
          financier_mrp_data={financier_mrp_data}
          market_mrp_data={market_mrp_data}
          car_otr={car_otr}
          loan_amount_offer={loan_amount_offer}
          backToFormHandler={this.backToFormHandler}
          taxOverdues={tax_amount}
          dealerName={selectedDealerName}
        />
      )
    }
    return (
      <>
        {this.state.basicAuth ? (
          <div className="mrp-contaier">
            <div className="financier-outer">
              <h2>MRP of Car</h2>
              <form
                className="mrp-fileds"
                onSubmit={this.getInventoryCarQuotes}
                autoComplete="off"
              >
                <h3>Financier MRP</h3>
                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      value={customer_region_options.filter(
                        ({ id }) => id === customer_region
                      )}
                      onChange={this.handleChangeOption.bind(
                        this,
                        "customer_region"
                      )}
                      options={customer_region_options}
                      name="customer_region"
                      placeholder={
                        "Customer Branch Region *"
                      }
                      className="react-select"
                      classNamePrefix="react-select"
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.customer_region ? errors.customer_region : ""}
                    </span>
                  </div>
                </fieldset>

                {/* <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      value={CustomerAreaOptions.filter(
                        ({ id }) => id === customer_area
                      )}
                      onChange={this.handleChangeOption.bind(
                        this,
                        "customer_area"
                      )}
                      options={CustomerAreaOptions}
                      name="customer_area"
                      placeholder={
                        "Customer Branch City *"
                      }
                      className="react-select"
                      classNamePrefix="react-select"
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.customer_area ? errors.customer_area : ""}
                    </span>
                  </div>
                </fieldset> */}

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      value={make_year_options.filter(
                        ({ id }) => id === oto_make_year
                      )}
                      onChange={this.handleChangeOption.bind(
                        this,
                        "oto_make_year"
                      )}
                      options={make_year_options?.filter(v => v.value >= 2008)}
                      name="oto_make_year"
                      placeholder={
                        "Year of Manufacturing *"
                      }
                      className="react-select"
                      classNamePrefix="react-select"
                      isSearchable="false"
                      getOptionLabel={({ id }) => id}
                      getOptionValue={({ value }) => value}
                    />
                    <span className="error-msg">
                      {errors.oto_make_year ? errors.oto_make_year : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      name="financier_make"
                      value={financier_make_options.filter(
                        ({ id }) => id === financier_make
                      )}
                      onChange={this.handleChangeOption.bind(this, "financier_make")}
                      options={financier_make_options}
                      placeholder={"Brand *"}
                      className="react-select"
                      classNamePrefix="react-select"
                      getOptionLabel={({ make }) => make}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.financier_make ? errors.financier_make : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      name="financier_model"
                      value={OtoModelOptions.filter(
                        ({ id }) => id === financier_model
                      )}
                      onChange={this.handleChangeOption.bind(this, "financier_model")}
                      options={OtoModelOptions}
                      placeholder={"Model *"}
                      className="react-select"
                      classNamePrefix="react-select"
                      getOptionLabel={({ model }) => model}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.financier_model ? errors.financier_model : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      name="financier_version"
                      value={OtoVersionOptions.filter(
                        ({ id }) => id === financier_version
                      )}
                      onChange={this.handleChangeOption.bind(
                        this,
                        "financier_version"
                      )}
                      options={OtoVersionOptions}
                      placeholder={"Version *"}
                      className="react-select"
                      classNamePrefix="react-select"
                      getOptionLabel={({ version }) => version}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.financier_version ? errors.financier_version : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      value={dealer_options.filter(
                        ({ id }) => id === dealer
                      )}
                      onChange={this.handleChangeOption.bind(this, "dealer")}
                      options={dealer_options}
                      name="dealer"
                      placeholder={"Dealer *"}
                      className="react-select"
                      classNamePrefix="react-select"
                      isSearchable="false"
                      getOptionLabel={({ value }) => value}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.dealer ? errors.dealer : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      value={VEHICLE_TYPE.filter(
                        ({ id }) => id === vehicle_type
                      )}
                      onChange={this.handleChangeOption.bind(
                        this,
                        "vehicle_type"
                      )}
                      options={VEHICLE_TYPE}
                      name="vehicle_type"
                      placeholder={
                        "Vehicle Type *"
                      }
                      className="react-select"
                      classNamePrefix="react-select"
                      isSearchable="false"
                      getOptionLabel={({ value }) => value}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.vehicle_type ? errors.vehicle_type : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="datepicker form-control dateragefiled daterange-picker col-md-12">
                  <div className="material">
                    <div className="datepicker">
                      <DatePicker
                        name="tax_validity"
                        id="tax_validity"
                        selected={
                          tax_validity ? new Date(tax_validity) : null
                        }
                        onChange={this.handleChangeOption.bind(
                          this,
                          "tax_validity"
                        )}
                        dateFormat="MMM/yyyy"
                        placeholderText={
                          "Tax Paid Upto *"
                        }
                        showMonthYearPicker
                        minDate={new Date(MAX_YEAR, 0)}
                        maxDate={new Date()}
                      />
                      <br></br>
                      <span className="error-msg">
                        {errors.tax_validity ? errors.tax_validity : ""}
                      </span>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <div className="material">
                    <input
                      type="number"
                      placeholder=" "
                      onChange={(e) =>
                        this.setState({ tax_amount: e.target.value })
                      }
                      value={tax_amount || ""}
                      name="tax_amount"
                      className="form-input"
                    />
                    <label
                      data-label={"Tax Value"}
                      className="form-label"
                    ></label>
                  </div>
                </fieldset>

                <h3>Market MRP</h3>
                <fieldset className="multiselect-dropDown">
                  <div className="material">
                  <Select
                      isMulti
                      options={market_mrp_city_options}
                      value={
                        market_mrp_city.length
                          ? market_mrp_city_options.filter((v) =>
                            market_mrp_city.includes(v["city_id"])
                          )
                          : []
                      }
                      name="market_mrp_city"
                      placeholder={
                        "Enter your location, area *"
                      }
                      // iconAfter="false"
                      onChange={(v) =>
                        this.handleChangeOption("market_mrp_city", v, true)
                      }
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      backspaceRemovesValue={false}
                      getOptionLabel={({ city_name }) => city_name}
                      getOptionValue={({ city_id }) => city_id}
                      components={{ ValueContainer: MultiSelectValueContainer, Option: InputOption }}
                      isClearable
                    />
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      value={make_year_options.filter(
                        ({ id }) => id === market_mrp_make_year
                      )}
                      onChange={this.handleChangeOption.bind(
                        this,
                        "market_mrp_make_year"
                      )}
                      options={make_year_options}
                      name="market_mrp_make_year"
                      placeholder={
                        "Year of Manufacturing *"
                      }
                      className="react-select"
                      classNamePrefix="react-select"
                      isSearchable="false"
                      getOptionLabel={({ id }) => id}
                      getOptionValue={({ value }) => value}
                    />
                    <span className="error-msg">
                      {errors.market_mrp_make_year
                        ? errors.market_mrp_make_year
                        : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      name="market_make"
                      value={market_make_options.filter(
                        ({ id }) => id === market_make
                      )}
                      onChange={this.handleChangeOption.bind(
                        this,
                        "market_make"
                      )}
                      options={market_make_options}
                      placeholder={"Make *"}
                      className="react-select"
                      classNamePrefix="react-select"
                      getOptionLabel={({ make }) => make}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.market_make ? errors.market_make : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      name="market_model"
                      value={MarketModelOptions.filter(
                        ({ id }) => id === market_model
                      )}
                      onChange={this.handleChangeOption.bind(
                        this,
                        "market_model"
                      )}
                      options={MarketModelOptions}
                      placeholder={"Model *"}
                      className="react-select"
                      classNamePrefix="react-select"
                      getOptionLabel={({ model }) => model}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.market_model ? errors.market_model : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      name="market_version"
                      value={MarketVersionOptions.filter(
                        ({ id }) => id === market_version
                      )}
                      onChange={this.handleChangeOption.bind(
                        this,
                        "market_version"
                      )}
                      options={MarketVersionOptions}
                      placeholder={
                        "Variant *"
                      }
                      className="react-select"
                      classNamePrefix="react-select"
                      isSearchable="false"
                      getOptionLabel={({ version }) => version}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.market_version ? errors.market_version : ""}
                    </span>
                  </div>
                </fieldset>

                <div className="btn-submit m-md-t">
                  <Button
                    className="btn-primary"
                    type="submit"
                    title={"SUBMIT"}
                    id="submit_approve"
                    name="submit_approve"
                  />
                </div>
              </form>
            </div>
            {loading ? <Loader /> : null}
          </div>
        ) : (
          <div className="view-login-popup">
            <Modal show={true}>
              <div className="modal-header">
                <h2>Password</h2>
              </div>
              <div className="modal-body">
                <div className="material login-field">
                  <input
                    type="password"
                    placeholder=" "
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    value={this.state.password || ""}
                    name="password"
                    className="form-input"
                  />
                  <label data-label={"Password"} className="form-label"></label>
                  <span className="error">{this.state.error}</span>
                </div>
                <button
                  className="btn-primary"
                  onClick={(e) => this.checkAuth(e)}
                >
                  Login
                </button>
              </div>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

export default FinancierMRP;