import axios from "axios";
import { toast } from "react-toastify";
import { API_URL, API_KEY } from "../config/constant";

const instance = axios.create({
  baseURL: API_URL.COMMON_GATEWAY_API,
  headers: {}
});

instance.interceptors.request.use(
  (request) => {
    let headers = {
      'Accept-Language': getSelectedLanguage(),
      "apikey": API_KEY
    };
    // if (request.apiKey) {
    //   headers["apiKey"] = request.apiKey;
    // }
    request.headers = headers;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response && response.data && response.data.status !== 200) {
      if (response.data.status === 400) {
        toast.error(`${response.data.message}`);        
      }
    }
    if (response.status === 401) {
      window.location = '/logout';
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status && error.response.status === 401) {
      window.location = '/logout';
    }
    return Promise.reject(error);
  }
);

const getSelectedLanguage = () => {
  return 'en'
}

const finalJson = {
  post(URL, body, headers = null) {
    return instance.post(`${URL}`, body, headers);
  },
  put(URL, body) {
    return instance.put(`${URL}`, body);
  },
  get(URL, query) {
    return instance.get(`${URL}`, { params: query });
  }
};

export default finalJson;