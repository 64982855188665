import React, {} from 'react';
import './App.scss';
import MainRoute from "./routes/routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "./refinance_App.scss";
// import "./refinance_App.css";

function App() {
  // let pathname = window.location.pathname;
  // if (pathname.includes('refinance')) {
  //     document.body.classList.remove("App");
  //     document.body.classList.add('loanbox-refinance-main')
  // }
  // else {
  //     document.body.classList.remove("loanbox-refinance-main");
  //     document.body.classList.add('App')
  // }
  return (
    <div className='App'>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
      <MainRoute />
    </div>
  );
}
export default App;

