import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import '../App.scss';
import FinancierMRP from '../view/inventory-funding/FinancierMRP';

class App extends Component {
  render() {
    return (
      <div>
        <Routes>
          <Route exact path="/" element={<FinancierMRP />} />
        </Routes>
      </div>
    );
  }
}

export default App;

