import React, { Component } from 'react';
import url_Image from "../../webroot/images/url-icn.svg";
import { GeneralService } from '../../services';

class MRPCarQuotes extends Component {
  render() {
    let { financier_mrp_data, market_mrp_data, backToFormHandler, loan_amount_offer, car_otr, taxOverdues, dealerName } = this.props;
    let avgMarketMRP = market_mrp_data && market_mrp_data.avg_price ? market_mrp_data.avg_price_formatted : false;

    return (
      <div className='mrp-contaier'>
        <div className='financier-outer'>
          <div className='mrp-heading-txt'>
            <h2>
              <i className='ic-arrow_back m-md-r link-btn' onClick={backToFormHandler}></i>
              MRP of Car</h2>
          </div>
          <div className='avg-financier-mrp-card'>
            <ul>
              <li>
                <label>OTO MRP</label>
                <span>RP {(car_otr && GeneralService.numberFormatWithDots(car_otr)) || "0"}</span>
              </li>
              <li>
                <label>LTV</label>
                <span>RP {(loan_amount_offer && GeneralService.numberFormatWithDots(loan_amount_offer)) || "0"}</span>
              </li>
              <li>
                <label>Tax Value</label>
                <span>RP {(taxOverdues && GeneralService.numberFormatWithDots(taxOverdues)) || "0"}</span>
              </li>
            </ul>
          </div>

          <div className='financer-card-outer quote-list'>
              <div className='financer-detail-card'>
                <div className='financer-img-txt'>
                  <div className='finacer-img'>
                    <img src={financier_mrp_data.financier_logo} alt="" className="" />
                  </div>
                  <div className='financer-detail-txt'>
                    <span className='car-details'> {!financier_mrp_data.make || !financier_mrp_data.model || !financier_mrp_data.variant ? "NA" : `${financier_mrp_data.make} ${financier_mrp_data.model} ${financier_mrp_data.variant}`}</span>
                    <span className='car-details'>{financier_mrp_data.make_year || "NA"}</span>
                  </div>
                </div>
                <div className='financer-more-detail'>
                  <div className='city-region'>
                    <span>Region: {financier_mrp_data.region || "NA"}</span>
                    {/* <span>City: {financier_mrp_data.city || "NA"}</span> */}
                    <span>Dealer: {dealerName || "NA"}</span>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <div className='avg-financier-mrp-card avg-market-txt-card'>
          <ul>
            <li>
              <label>Avg Market MRP</label>
              {avgMarketMRP ? (
                <span>{avgMarketMRP || "NA"}</span>
              ) : (
                <span className='link-btn' onClick={backToFormHandler}>Add Details</span>
              )}
            </li>
          </ul>
        </div>
        {market_mrp_data && market_mrp_data.car_list && market_mrp_data.car_list.length ? (
          <div className='financer-card-outer quote-list'>
            {market_mrp_data.car_list.map(market_data => (
              <div className='avg-market-car-detail-card'>
                <ul>
                  <li>
                    <label>Make Model Variant</label>
                    <span>{market_data.title || "NA"}</span>
                  </li>
                  <li>
                    <label>Manufacturing Year</label>
                    <span>{market_data.make_year || "NA"}</span>
                  </li>
                  <li>
                    <label>City</label>
                    <span>{market_data.city_name || "NA"}</span>
                  </li>
                  <li>
                    <label>Market MRP</label>
                    <span>{market_data.price_formatted || "NA"}</span>
                  </li>
                </ul>
                {market_data.url && (
                  <a className='url-txt' href={market_data.url} target='blank'>Website <img alt="" src={url_Image} className="" />
                  </a>
                )}
              </div>
            ))}
          </div>
        ) : null}
      </div>

    )
  }
}

export default MRPCarQuotes;